export const CHAIN_INFO = {
  chainId: 'AELF',
  exploreUrl: 'https://testnet.aelfscan.io/AELF/',
  exploreDomain: 'https://testnet.aelfscan.io/',
  rpcUrl: 'https://aelf-test-node.aelf.io',
};

export const TOKEN_CONTRACT = 'JRmBduh4nXWi1aXgdUsj5gJrzeZb2LxmrAbf7W99faZSvoAaE';
export const ETRANSFER_CONTRACT = '4xWFvoLvi5anZERDuJvzfMoZsb6WZLATEzqzCVe8sQnCp2XGS';
export const CA_CONTRACT = 'iupiTuL2cshxB9UNauXNXe9iyCcqka7jCotodcEHGpNXeLzqG';

export const CA_CONTRACT_V2 = '238X6iw1j8YKcHvkDYVtYVbuYk2gJnK8UoNpVCtssynSpVC8hb';
